define("ember-modal-dialog/templates/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TUs7qIvY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[25,[\"modalDialogComponentName\"]]],[[\"wrapperClass\",\"wrapperClassNames\",\"overlayClass\",\"overlayClassNames\",\"containerClass\",\"containerClassNames\",\"hasOverlay\",\"translucentOverlay\",\"clickOutsideToClose\",\"destinationElementId\",\"overlayPosition\",\"tetherTarget\",\"legacyTarget\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"targetOffset\",\"offset\",\"tetherClassPrefix\",\"constraints\",\"attachmentClass\",\"targetAttachmentClass\",\"stack\",\"value\",\"onClickOverlay\",\"onClose\"],[[25,[\"wrapperClass\"]],[25,[\"wrapperClassNames\"]],[25,[\"overlayClass\"]],[25,[\"overlayClassNames\"]],[25,[\"containerClass\"]],[25,[\"containerClassNames\"]],[25,[\"hasOverlay\"]],[25,[\"translucentOverlay\"]],[25,[\"clickOutsideToClose\"]],[25,[\"destinationElementId\"]],[25,[\"overlayPosition\"]],[25,[\"tetherTarget\"]],[25,[\"target\"]],[25,[\"attachment\"]],[25,[\"targetAttachment\"]],[25,[\"targetModifier\"]],[25,[\"targetOffset\"]],[25,[\"offset\"]],[25,[\"tetherClassPrefix\"]],[25,[\"constraints\"]],[25,[\"attachmentClass\"]],[25,[\"targetAttachmentClass\"]],[25,[\"stack\"]],[25,[\"value\"]],[29,\"action\",[[24,0,[]],\"onClickOverlay\"],null],[29,\"action\",[[24,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/modal-dialog.hbs"
    }
  });
  _exports.default = _default;
});