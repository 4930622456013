define("ember-modal-dialog/templates/components/liquid-tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t/pPnoCq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"hasOverlay\"]]],null,{\"statements\":[[4,\"liquid-wormhole\",null,[[\"stack\",\"class\"],[\"modal-overlay\",\"liquid-dialog-container\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[23,\"overlayClassNamesString\"]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"onClickOverlay\"]]],null]],[11,\"tabindex\",\"-1\"],[11,\"data-emd-overlay\",\"\"],[9],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"liquid-tether\",null,[[\"class\",\"target\",\"attachment\",\"targetAttachment\",\"targetModifier\",\"classPrefix\",\"offset\",\"targetOffset\",\"constraints\",\"stack\",\"value\"],[[25,[\"containerClassNamesString\"]],[25,[\"tetherTarget\"]],[25,[\"attachment\"]],[25,[\"targetAttachment\"]],[25,[\"targetModifier\"]],[25,[\"tetherClassPrefix\"]],[25,[\"offset\"]],[25,[\"targetOffset\"]],[25,[\"constraints\"]],[25,[\"stack\"]],[25,[\"value\"]]]],{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/liquid-tether-dialog.hbs"
    }
  });
  _exports.default = _default;
});