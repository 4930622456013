define("ember-resize-text/mixins/resize-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    classNames: ['resize-text'],
    minSize: 2,
    maxSize: 80,
    textMeasurer: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.addEventListener('resize', this.set('_resizeHandler', this.scaleFont.bind(this)), false);
      this.element.style['white-space'] = 'nowrap';
      this.scaleFont();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('resize', this.get('_resizeHandler'), false);
    },
    scaleFont: function scaleFont() {
      var style = getComputedStyle(this.element);
      var minSize = this.get('minSize');
      var maxSize = this.get('maxSize');
      var container = this.get('containerElement');
      var elementWidth;
      if (container) {
        elementWidth = container.clientWidth;
      } else {
        elementWidth = this.element.clientWidth;
      }
      var fontSize = this.get('textMeasurer').fitTextSize(this.element.innerText, elementWidth, style.fontStyle + ' 14px ' + style.fontFamily);
      if (fontSize > maxSize) {
        fontSize = maxSize;
      } else if (fontSize < minSize) {
        fontSize = minSize;
      }
      this.element.style['font-size'] = fontSize + 'px';
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);
      this.scaleFont();
    }
  });
  _exports.default = _default;
});