define("ember-parachute/decorators/-private/query-params-for", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-parachute/query-params"], function (_exports, _defineProperty2, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addQueryParamFor = addQueryParamFor;
  _exports.getQueryParamsFor = getQueryParamsFor;
  var QP_MAP = new WeakMap();
  function getQueryParamsFor(klass) {
    QP_MAP.set(klass, QP_MAP.get(klass) || new _queryParams.default());
    return QP_MAP.get(klass);
  }
  function addQueryParamFor(klass, key, definition) {
    QP_MAP.set(klass, getQueryParamsFor(klass).extend((0, _defineProperty2.default)({}, key, definition || {})));
  }
});