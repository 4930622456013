define("ember-parachute/decorators/with-parachute", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "ember-parachute/query-params"], function (_exports, _objectSpread2, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withParachute;
  function withParachute(desc) {
    return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, desc), {}, {
      finisher: function finisher(klass) {
        klass.reopen(new _queryParams.default().Mixin);
        return klass;
      }
    });
  }
});