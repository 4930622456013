define("ember-resize-text/components/resize-text", ["exports", "ember-resize-text/templates/components/resize-text", "ember-resize-text/mixins/resize-text"], function (_exports, _resizeText, _resizeText2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_resizeText2.default, {
    layout: _resizeText.default
  });
  _exports.default = _default;
});