define("ember-anchor/mixins/controller-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.injectConfig = injectConfig;
  function injectConfig() {
    return Ember.computed(function () {
      var owner = Ember.getOwner ? Ember.getOwner(this) : this.container;
      return owner.lookup('config:ember-anchor');
    });
  }
  var _default = Ember.Mixin.create({
    anchorQueryParam: Ember.computed.oneWay('_anchorConfig.anchorQueryParam'),
    _anchorConfig: injectConfig(),
    init: function init() {
      var qpValue = this.get('anchorQueryParam');
      this.queryParams = qpValue ? [qpValue] : [];
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});