define("ember-modal-dialog/templates/components/in-place-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2b0ig8/R",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[29,\"concat\",[[25,[\"containerClassNamesString\"]],\" \",[25,[\"attachmentClass\"]],\" \",[25,[\"containerClass\"]]],null]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/in-place-dialog.hbs"
    }
  });
  _exports.default = _default;
});