define("ember-parachute/decorators/query-param", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "ember-parachute/-private/symbols", "ember-parachute/decorators/-private/query-params-for"], function (_exports, _toConsumableArray2, _objectSpread2, _symbols, _queryParamsFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;
  function createDescriptor(desc, qpDefinition) {
    qpDefinition = qpDefinition || {};
    var descriptor = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, desc), {}, {
      finisher: function finisher(klass) {
        (0, _queryParamsFor.addQueryParamFor)(klass, desc.key, qpDefinition);
        klass.reopen((0, _queryParamsFor.getQueryParamsFor)(klass).Mixin);
        var proto = klass.proto();

        // Remove duplicate queryParams created by the multiple mixins
        if (Array.isArray(proto.queryParams)) {
          var queryParams = Ember.A((0, _toConsumableArray2.default)(proto.queryParams));
          var parachuteQueryParams = queryParams.filterBy(_symbols.PARACHUTE_QPS, true);

          // Keep the newest one
          parachuteQueryParams.pop();
          // Remove the old ones
          queryParams.removeObjects(parachuteQueryParams);
          proto.queryParams = queryParams.toArray();
        }
        return klass;
      }
    });
    if (desc.kind === 'field') {
      if (typeof desc.initializer === 'function') {
        qpDefinition.defaultValue = desc.initializer();
      }
      descriptor.initializer = function initializer() {
        return qpDefinition.defaultValue;
      };
    }
    return descriptor;
  }
  function queryParam(qpDefinition) {
    // Handle `@queryParam` usage
    if ("".concat(qpDefinition) === '[object Descriptor]') {
      return createDescriptor(qpDefinition);
    }

    // Handle `@queryParam()` usage
    return function (desc) {
      return createDescriptor(desc, qpDefinition);
    };
  }
});