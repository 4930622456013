define("ember-parachute/utils/lookup-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lookupController;
  /**
   * Lookup controller on route.
   *
   * @export
   * @param {Ember.Route} route
   * @param {function(any): any} [ownerLookupFn=getOwner]
   * @returns {Ember.Controller}
   */
  function lookupController(route) {
    var ownerLookupFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Ember.getOwner;
    var controller = Ember.get(route, 'controller');
    if (!controller) {
      /**
       * If the controller doesnt exist, use the class proto instead. Before, we
       * would create the controller if it didnt exist which caused a lot of issues
       * (especially with authentication) due to the controller being created
       * prematurely.
       */
      var controllerName = Ember.get(route, 'controllerName') || Ember.get(route, 'routeName');
      var factory = ownerLookupFn(route).factoryFor("controller:".concat(controllerName));
      return factory.class.proto();
    }
    return controller;
  }
});