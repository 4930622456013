define("ember-resize-text/templates/components/resize-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RwTwsSuB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-resize-text/templates/components/resize-text.hbs"
    }
  });
  _exports.default = _default;
});